import { LINKS } from "./links";

// different array - no shop, different order of drawing items
export const NAV_LINKS = [
  {
    to: LINKS.solutions,
    label: "Solutions",
    width: 94,
    hasSections: 2,
    nodes: [
      {
        label: 'Industries',
      },
      {
        label: 'Aviation',
        to: LINKS.aviation,
      },
      {
        label: 'Construction',
        to: LINKS.construction,
      },
      {
        label: 'Mechanical, Electrical, Plumbing',
        to: LINKS.mechanicalElectricalPlumbing,
      },
      {
        label: 'Manufacturing (OEM)',
        to: LINKS.manufacturingOem,
      },
      {
        label: 'Emergency Services',
        to: LINKS.emergencyServices,
      },
      {
        label: 'Other Industries',
        to: LINKS.otherIndustries
      },
      {
        label: 'Portable Toilets',
        to: LINKS.portableToilets,
      },
      {
        label: 'Use Cases',
      },
      {
        label: 'QR code Asset Tracking',
        to: LINKS.qrCodeAssetTracking,
      },
      {
        label: 'Digitization of Documents',
        to: LINKS.digitisationOfDocuments
      },
      {
        label: 'Equipment Maintenance',
        to: LINKS.equipmentMaintenance,
      },
      {
        label: 'Daily Health Checks',
        to: LINKS.dailyHealthChecks,
      },
      {
        label: 'Routine Inspections',
        to: LINKS.routineInspections,
      },
      {
        label: 'Other Use Cases',
        to: LINKS.otherUseCases,
      },
      {
        label: 'Inventory Management',
        to: LINKS.inventoryManagement,
      },
    ]
  },
  {
    to: LINKS.features,
    label: "Features",
    width: 90,
    hasSections: 0,
    nodes: [
      {
        label: 'Design & Customize',
        to: LINKS.design,
      },
      {
        label: 'Capture & Store',
        to: LINKS.capture,
      },
      {
        label: 'Report & Analyze',
        to: LINKS.viewAndReport,
      },
    ],
  },
  {
    to: LINKS.pricing,
    label: "Pricing",
  },
  {
    to: LINKS.resources,
    label: "Resources",
    width: 102,
    hasSections: 0,
    nodes: [
      {
        label: 'Blog',
        to: LINKS.blog,
      },
      {
        label: 'Case Studies',
        to: LINKS.caseStudies,
      },
      {
        label: 'Support',
        to: LINKS.support,
      },
    ]
  },
  {
    to: LINKS.contact,
    label: "Contact Us",
  },
]

export const NAV_LINKS_SIDE_MENU = [
  {
    ...NAV_LINKS[0],
    nodes: [
      NAV_LINKS[0].nodes[0],
      NAV_LINKS[0].nodes[1],
      NAV_LINKS[0].nodes[3],
      NAV_LINKS[0].nodes[5],
      NAV_LINKS[0].nodes[7],
      NAV_LINKS[0].nodes[2],
      NAV_LINKS[0].nodes[4],
      NAV_LINKS[0].nodes[6],
      NAV_LINKS[0].nodes[8],
      NAV_LINKS[0].nodes[9],
      NAV_LINKS[0].nodes[11],
      NAV_LINKS[0].nodes[13],
      NAV_LINKS[0].nodes[15],
      NAV_LINKS[0].nodes[10],
      NAV_LINKS[0].nodes[12],
      NAV_LINKS[0].nodes[14],
    ]
  },
  NAV_LINKS[1],
  NAV_LINKS[2],
  NAV_LINKS[3],
  NAV_LINKS[4],
];

